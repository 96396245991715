<template>
  <div id="payment-app">
    <div v-if="isLoading">결제 창을 불러오는 중...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <p>결제 금액: {{ price }}원</p>
      <p>결제 진행 중입니다. 잠시만 기다려주세요.</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const isLoading = ref(true);
    const error = ref(null);
    const price = ref(0);
    const reservationId = ref('');
    const orderToken = ref('');

    const loadPaymentScript = () => {
      const script = document.createElement('script');
      script.src = "https://cpay.payple.kr/js/v1/payment.js";
      script.onload = () => initiatePayment();
      script.onerror = () => {
        error.value = "결제 스크립트 로딩에 실패했습니다.";
        isLoading.value = false;
      };
      document.head.appendChild(script);
    };

    const initiatePayment = () => {
      if (typeof window.PaypleCpayAuthCheck === 'function') {
        window.PaypleCpayAuthCheck({
          PCD_PAY_TYPE: "card",
          PCD_PAY_WORK: "CERT",
          PCD_CARD_VER: "02",
          PCD_PAY_GOODS: "유카주차장",
          PCD_PAY_TOTAL: price.value,
          PCD_RST_URL: `https://www.u-car.co.kr/dev-api/payments/reservations/web-view?reservationId=${reservationId.value}&orderToken=${orderToken.value}`,
          clientKey: "test_DF55F29DA654A8CBC0F0A9DD4B556486"
        });
      } else {
        error.value = '페이플 결제 모듈을 불러오는 데 실패했습니다.';
      }
    };

    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const priceParam = urlParams.get('price');
      const reservationIdParam = urlParams.get('reservationId');
      const orderTokenParam = urlParams.get('orderToken');


      if (priceParam && reservationIdParam && orderTokenParam) {
        price.value = priceParam;
        reservationId.value = reservationIdParam;
        orderToken.value = orderTokenParam;
        loadPaymentScript();
      } else {
        error.value = "필수 결제 정보가 누락되었습니다.";
        isLoading.value = false;
      }
    });

    return {
      isLoading,
      error,
      price,
      reservationId,
      orderToken
    };
  }
};
</script>

<style scoped>
#payment-app {
  padding: 20px;
  width: 100%;
  height: 100vh;
  background-color: white;
  text-align: center;
}
</style>
