import axios from 'axios';

// 결제 취소 요청
export async function cancelPayment(reservationId, password) {
  try {
    const url = `https://www.u-car.co.kr/api/parking-areas/reservations/non-customer/${reservationId}/cancel`;
    const payload = JSON.stringify({ "password": password }); 
    const response = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  } catch (error) {
    console.error('결제 취소 에러:', error);
    throw error;
  }
}