<template>
  <div class="reservation-form-wrapper">
      <div class="reservation-form">
          <h2>온라인 예약하기</h2>
          <form @submit.prevent="submitForm">
              <div class="form-group">
                <label>연락처</label>
                <input type="text" v-model="customer.phone" placeholder="ex. 010-1234-5678" />
              </div>

              <div class="form-group">
                <label>탑승노선</label>
                <div class="line-group">
                  <label for="domestic" class="radio-button">
                    <input type="radio" id="domestic" value="국내" v-model="route" />
                    국내
                  </label> 
                  <label for="international" class="radio-button">
                    <input type="radio" id="international" value="국제" v-model="route" />
                    국제
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label>차량 입고</label>
                <div class="date-time-group">
                  <div class="date-time-line">
                    <input type="date" v-model="parkingReservation.startDate" :min="minDate" :max="maxDate"/>
                    
                    <select v-model="parkingReservation.startHour" placeholder="시">
                      <option value="" disabled selected>시</option> 
                      <option v-for="hour in hours" :key="`start-hour-${hour}`" :value="hour">
                          {{ hour }} 시
                      </option>
                    </select>

                    <select v-model="parkingReservation.startMinute" placeholder="분">
                      <option v-for="minute in minutes" :key="`start-minute-${minute}`" :value="minute">
                        {{ minute }} 분
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                  <label>차량 출고</label>
                  <div class="date-time-group">
                    <div class="date-time-line">
                      <input type="date" v-model="parkingReservation.endDate" :min="parkingReservation.startDate" :max="maxDate"/>
                      <select v-model="parkingReservation.endHour" placeholder="시">
                        <option v-for="hour in endhours" :key="`end-hour-${hour}`" :value="hour">
                          {{ hour }} 시
                        </option>
                      </select>
                    <select v-model="parkingReservation.endMinute" placeholder="분">
                        <option v-for="minute in minutes" :key="`end-minute-${minute}`" :value="minute">
                            {{ minute }} 분
                        </option>
                    </select>
                  </div>
                </div>
                </div>

              <div class="form-group">
                <label>주차장 선택</label>
                <div class="facility-group">
                  <input type="text" v-model="parkingArea.sido" disabled>
                  <input type="text" v-model="parkingArea.sigungu" disabled>
                  <input type="text" v-model="parkingArea.dong" disabled>
                  <select v-model="selectedArea.name" @click="checkDateSelected" @change="handleParkingLotChange">
                    <option value="">주차장 선택</option>
                    <option v-for="parkingLot in parkingArea.list" :key="parkingLot.id" :value="parkingLot.parkingAreaName">
                      {{ parkingLot.parkingAreaName }}
                    </option>
                  </select>             
                </div>
                <label>주차장 상세주소 : {{ selectedArea.fullAddress }}</label>
              </div>

              <div class="form-group">
                <label>예상금액 </label>
                <div class="fee-info">
                  <input type="text" v-model="estimateFee" placeholder="₩" readonly>
                  <!-- <img src="@/img/info.png" alt="Info Icon" @click="showAmountInfoModal" class="info-icon"> -->
                </div>
                <p v-if="isMinPayMet" class="highlight">최소 이용 금액은 {{ selectedArea.minPay }}원입니다. 차량 입출고 기간을 다시 설정해주세요.</p>
                <!-- <FeeModal :show="isAmountInfoModalVisible" @close="hideAmountInfoModal" :parkingFees="parkingFees"/> -->
              </div>

              <div class="form-group">
                <div class="car-group">
                  <div class="group-row no-margin">
                    <label>차량 기종</label>
                    <input type="text" v-model="vehicle.type" placeholder="ex. 그랜저" />
                  </div>
                  <div class="group-row no-margin">
                    <label>차량 번호</label>
                    <input type="text" v-model="vehicle.number" placeholder="ex. 123가4567" />
                  </div>
                </div>
              </div>
              
              <!-- <div class="form-group">
                <label>수하물</label>
                <div class = "luggage-group">                  
                  <select v-model="carrierCount">                    
                    <option value="">캐리어 개수</option>
                    <option v-for="cnt in luggageCnt" :key="cnt" :value="cnt" >
                        {{ cnt }} 개
                    </option>
                  </select>

                  <select v-model="golfClubCount">                    
                    <option value="">골프백 개수</option>
                    <option v-for="cnt in luggageCnt" :key="cnt" :value="cnt" >
                        {{ cnt }} 개
                    </option>
                  </select>
                </div>
              </div> -->

              <!-- <div class="form-group">
                <label>버스탑승 예정인원</label>
                <select v-model="nonMember">
                  <option v-for="cnt in personCnt" :key="cnt" :value="cnt">
                      {{ cnt }} 명
                  </option>
                </select>
              </div> -->

              <div class="form-group">
                  <label>등록암호(예약 수정, 조회 시 필요)</label>
                  <input type="password" v-model="registrationConfirmation" placeholder="등록암호" />
              </div>

              <div class="form-group">
                <label>개인정보처리방침(필수)</label>
                <textarea readonly v-model="PrivacyPolicyContent"></textarea>
                <label class="agree-group">
                  <input type="checkbox" v-model="agreePrivacyPolicy" />
                  개인정보처리방침 내용을 모두 확인했으며 동의합니다.
                </label> 
              </div>

              <div class="form-group">
                <label>이용약관(필수)</label>
                <textarea readonly v-model="TermsofServiceContent"></textarea>
                <label class="agree-group">
                  <input type="checkbox" v-model="agreeTermsOfService" />
                  이용약관 내용을 모두 확인했으며 동의합니다.
                </label> 
              </div>

              <div class="form-group">
                <label>기사알선포함 송출자동차 대여서비스 이용약관(필수)</label>
                <textarea readonly v-model="VehicleServiceContent"> </textarea>
                <label class="agree-group">
                  <input type="checkbox" v-model="agreeDriverServiceTerms" />
                  기사알선포함 송출자동차 대여서비스 이용약관 내용을 모두 확인했으며 동의합니다.
                </label> 
              </div>

              <div class="agree-group">
                  <button type="button">예약하기</button>
              </div>
              
              <ConfirmModal 
                :show="isModalVisible" 
                @confirm="confirmReservation" 
                @cancel="hideModal" 
                :estimatedAmount="estimatedAmount"
                :reservationDetails="getReservationDetails()"
                :parkinglot= "selectedArea"
              />
              <AlertFeeModal 
                :show="alertFeeVisible" 
                @confirm="hideAlertFee" 
                :estimatedFee= "estimatedAmount"
                :minPay = "selectedArea.minPay"
              />
          </form>
      </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils/dataFormatUtils';
import ConfirmModal from '../components/ConfirmModal.vue';
// import FeeModal from '../components/FeeModal.vue';
import { getParkingFees, getParkingList } from '@/api/userapi';
import AlertFeeModal from './AlertFeeModal.vue';

export default {
  components: { ConfirmModal, AlertFeeModal },
  data() {
    const today = new Date();
    const todayISO = today.toISOString().slice(0, 10);
    const threeMonthsLater = new Date(today.setMonth(today.getMonth() + 3));
    const threeMonthsLaterISO = threeMonthsLater.toISOString().slice(0, 10);
    const currentHour = today.getHours();

    return {
      hours: Array.from({ length: 21 - currentHour +1}, (_, i) => String(i + currentHour).padStart(2, '0')),
      endhours: Array.from({ length: 17}, (_, i) => String(i+5).padStart(2, '0')),
      minutes: Array.from({ length: 6 }, (_, i) => String(i*10).padStart(2, '0')),
      personCnt: Array.from({ length: 7 }, (_, i) => String(i+1)),
      luggageCnt: Array.from({ length: 5 }, (_, i) => String(i)),
      PrivacyPolicyContent: '',
      TermsofServiceContent: '',
      VehicleServiceContent: '',
      minDate: todayISO,
      maxDate: threeMonthsLaterISO,
      customer: { phone: '', password: '' },
      vehicle: { number: '', type: '' },
      parkingReservation: { 
        startDate: todayISO, 
        endDate: '', 
        startHour: String(currentHour + 1).padStart(2, '0'),
        startMinute: '00',
        endHour: '05',
        endMinute: '00'
      },
      entryTime: '',
      departTime: '',
      busReservation: { passengers: 1 },
      agreePrivacyPolicy: false,
      agreeTermsOfService: false,
      agreeDriverServiceTerms: false,
      carrierCount: '',
      golfClubCount:'',
      parkingArea: { 
        sido: '부산광역시', 
        sigungu: '강서구', 
        dong: '대저2동', 
        list: [] 
      },
      selectedArea: { 
        name: '',
        id: '',
        fullAddress: '',
        minPay: null,
      },
      nonMember: '1',
      estimatedAmount: null,
      route: '',
      registrationConfirmation: '',
      isModalVisible: false,
      isAmountInfoModalVisible: false,
      alertFeeVisible: false,
      parkingFees: null,
      allParkingAreas: []
    };
  },
  mounted() {
    fetch('/privacy-policy.txt')
    .then(response => response.text())
    .then(html => {
      this.PrivacyPolicyContent = html;
    });
    fetch('/terms-of-services.txt')
    .then(response => response.text())
    .then(text => {
      this.TermsofServiceContent = text;
    });
    fetch('/vehicle-services.txt')
    .then(response => response.text())
    .then(text => {
      this.VehicleServiceContent = text;
    });
  },
  watch: {
    'parkingReservation.startDate': function(newDate) {
      const currentHour = new Date().getHours();
      const today = new Date().toISOString().slice(0, 10);

      if (newDate === today) {
        this.hours = this.generateHours(currentHour + 1);

        if (parseInt(this.parkingReservation.startHour, 10) <= currentHour) {
          this.parkingReservation.startHour = String(currentHour + 1).padStart(2, '0');
        }
      } else {
        this.hours = this.generateHours(5);
        this.parkingReservation.startHour = '05';
        this.parkingReservation.endHour = '05';
      }

      if (new Date(newDate) > new Date(this.parkingReservation.endDate)) {
        this.parkingReservation.endDate = newDate;
      }
      this.checkDates();
      this.calculateEstimatedAmount();
    },
    'parkingReservation.endDate': function() {
      this.checkDates();
      this.calculateEstimatedAmount();
    },
    'parkingReservation.startHour': function() {
      this.checkDates();
      this.calculateEstimatedAmount();
    },
    'parkingReservation.startMinute': function() {
      this.calculateEstimatedAmount();
    },
    'parkingReservation.endHour': function() {
      this.checkDates();
      this.calculateEstimatedAmount();
    },
    'parkingReservation.endMinute': function() {
      this.calculateEstimatedAmount();
    }
  },
  computed: {
    estimateFee: {
      get() {
        return formatCurrency(this.estimatedAmount);
      }
    },
    isMinPayMet() {
      if (this.estimatedAmount) {
        if (this.selectedArea.minPay > this.estimatedAmount){
          return true
        } else {
          return false
        }
      }
      return false;
    },
    canSubmit() {
      return this.customer.phone && this.route && this.selectedArea.id &&
             this.parkingReservation.startDate && this.parkingReservation.endDate &&
             this.parkingReservation.startHour && this.parkingReservation.startMinute &&
             this.parkingReservation.endHour && this.parkingReservation.endMinute &&
             this.vehicle.number && this.vehicle.type &&
            //  this.carrierCount && this.golfClubCount && 
             this.agreePrivacyPolicy &&this.agreeTermsOfService && this.agreeDriverServiceTerms;
    }
  },
  methods: {
    generateHours(minHour) {
      const maxHour = 21;
      if (minHour > maxHour) return [];

      return Array.from({ length: maxHour - minHour +1}, (_, i) => String(i + minHour).padStart(2, '0'));
    },
    checkDates() {
      if (this.parkingReservation.startDate && this.parkingReservation.endDate) {
        this.fetchParkingList();
      }
    },
    async calculateEstimatedAmount() {
      if (!this.parkingReservation.startDate || !this.parkingReservation.endDate || !this.selectedArea.id) {
        this.estimatedAmount = '';
        return;
      }
      const startTime = `${this.parkingReservation.startDate}T${this.parkingReservation.startHour}:${this.parkingReservation.startMinute}:00`;
      const endTime = `${this.parkingReservation.endDate}T${this.parkingReservation.endHour}:${this.parkingReservation.endMinute}:00`;

      const reservDate = { startTime, endTime };
      
      try {
        const fee = await getParkingFees(reservDate, this.selectedArea.id);
        this.estimatedAmount = fee; // 금액을 숫자로 저장
      } catch (error) {
        console.error("예상 금액 계산 오류:", error);
        this.estimatedAmount = "가격 정보 없음";
      }
    },
    async fetchParkingList() {
      const startTime = `${this.parkingReservation.startDate}T${this.parkingReservation.startHour}:${this.parkingReservation.startMinute}:00`;
      const endTime = `${this.parkingReservation.endDate}T${this.parkingReservation.endHour}:${this.parkingReservation.endMinute}:00`;

      try {
        this.parkingArea.list = await getParkingList(startTime, endTime);
      } catch (error) {
        console.error('Error fetching dong:', error);
      }
    },
    checkDateSelected() {
      if (!this.parkingReservation.startDate || !this.parkingReservation.endDate) {
        alert("차량 입고 · 출고일을 먼저 선택해주세요.");
        this.selectedArea.name = '';  // 선택을 초기화합니다.
      }
    },

    handleParkingLotChange() {
      this.updateSelectedParkingLotId();
      this.calculateEstimatedAmount();
    },

    updateSelectedParkingLotId() {
      const selectedParkingLot = this.parkingArea.list.find(lot => lot.parkingAreaName === this.selectedArea.name);
      this.selectedArea.id = selectedParkingLot ? selectedParkingLot.id : '';
      this.selectedArea.fullAddress = selectedParkingLot ? selectedParkingLot.fullAddress : '';
      this.selectedArea.minPay = selectedParkingLot ? selectedParkingLot.minPay: '';
    },

    getReservationDetails() {
      const startTime = `${this.parkingReservation.startDate}T${this.parkingReservation.startHour}:${this.parkingReservation.startMinute}:00`;
      const endTime = `${this.parkingReservation.endDate}T${this.parkingReservation.endHour}:${this.parkingReservation.endMinute}:00`;
      const price = `${this.estimatedAmount}`;

      return {
        startTime: startTime,
        endTime: endTime,
        price: parseInt(price),
        flightType: this.route === '국내' ? 'DOMESTIC' : 'OVERSEAS',
        customerInfo: {
          phone: this.customer.phone,
          password: this.registrationConfirmation
        },
        vehicleInfo: {
          number: this.vehicle.number,
          type: this.vehicle.type
        },
        carrierCount: 0,
        golfClubCount: 0,
        peopleCount: 0,
        provide: true
      };
    },

    confirmReservation() {
      if (this.canSubmit) {
        this.isModalVisible = true;
      } else {
        alert("모든 필수항목을 입력하고 동의해주세요.");
      }
    },

    showModal() {
      if (this.estimatedAmount < this.selectedArea.minPay) {
        this.alertFeeVisible = true;
        return
      }
      if (this.canSubmit) {
        this.isModalVisible = true;
      } else {
        alert("모든 필수항목을 입력하고 동의해주세요.");
      }
    },

    hideModal() {
      this.isModalVisible = false;
    },
    hideAlertFee() {
      this.alertFeeVisible = false;
    },
    showAmountInfoModal() {
      this.isAmountInfoModalVisible = true;
    },
    hideAmountInfoModal() {
      this.isAmountInfoModalVisible = false;
    }
  }
};
</script>

<style src="../styles/reservecontent.css"></style>


