<template>
  <div id="payple-result">
  </div>

</template>


<script>
export default {
  data() {
    return {
      status: '', 
      msg: '' ,
      code: '',
      payCardName: '',
      payTime: ''
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.status = urlParams.get('status');
    this.msg = urlParams.get('msg');
    this.code = urlParams.get('code');
    this.payCardName = urlParams.get('payCardName');
    this.payTime = urlParams.get('payTime');
    
    this.sendResultToApp();
  },
  methods: {
    sendResultToApp() {
      const message = {
        status: this.status,
        msg: this.msg,
        code: this.code,
        payCardName: this.payCardName,
        payTime: this.payTime? this.payTime.replace("T", " "): null,
      };
      
      const jsonString = JSON.stringify(message);

      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.onPaymentResult) {
        window.webkit.messageHandlers.onPaymentResult.postMessage(jsonString);
      } else if (window.Android) {
        window.Android.onPaymentResult(jsonString);
      } else {
        alert(`결제를 실패하였습니다. 홈 화면으로 이동합니다.`);
        window.location.href = '/'; 
      }
    }
  }
};
</script>