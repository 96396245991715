import { createRouter, createWebHistory } from 'vue-router'
import WebPage from '@/pages/WebPage.vue'
import PrivatePage from '@/pages/PrivatePage.vue'
import LocationPage from '@/pages/LocationPage.vue'
import OtherPage from '@/pages/OtherPage.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import PaypleWebview from '@/pages/PaypleWebview.vue'
import PayplecancelWebview from '@/pages/PayplecancelWebview.vue'
import WebViewResult from '@/components/WebViewResult.vue'
import DevPaypleWebview from '@/pages/DevPaypleWebview.vue'
import DevPayplecancelWebview from '@/pages/DevPayplecancelWebview.vue'

const routes = [
  { path: '/', name: 'Web', component: WebPage },
  
  { path: '/location', name: 'PrivatePage', component: PrivatePage },
  { path: '/privacy', name: 'LocationPage', component: LocationPage },
  { path: '/n_privacy', name: 'OtherPage', component: OtherPage },
  { path: '/:catchAll(.*)', name: 'NotFound', component: NotFoundPage },
  { path: '/pay', name: 'PaypleWebview', component: PaypleWebview},
  { path: '/payrefund', name: 'PayplecancelWebview', component: PayplecancelWebview},
  { path: '/pay/result', name: 'WebViewResult', component: WebViewResult},
  { path: '/pay.html', redirect: '/'},
  { path: '/dev-pay', name: 'DevPaypleWebview', component: DevPaypleWebview},
  { path: '/dev-payrefund', name: 'DevPayplecancelWebview', component: DevPayplecancelWebview},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
