<template>
  <div class="solution-page">
    <!-- <button class="fee-info-button" @click="showFeeInfoModal">요금안내 보기</button> -->
    <!-- <FeeInfoModal :show="isFeeInfoModalVisible" @close="hideFeeInfoModal" /> -->
  </div>
</template>

<script>
// import FeeInfoModal from '../components/FeeModal.vue';

export default {
  // components: { FeeInfoModal },
  data() {
    return {
      isFeeInfoModalVisible: false,
    };
  },
  methods: {
    showFeeInfoModal() {
      this.isFeeInfoModalVisible = true;
    },
    hideFeeInfoModal() {
      this.isFeeInfoModalVisible = false;
    }
  }
};
</script>

<style src="../styles/company.css"></style>

<style scoped>
.solution-page {
  display: flex;
  flex-direction: column;
  position: relative;
}

.fee-info-button {
  position: absolute;
  bottom: 35%;
  left: 16%; 
  background-color: var(--admin-button-color);
  font-size: 1.2rem;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
@media (max-width: 900px) {
  .fee-info-position {padding: 540px 0; text-align: center;}
}
</style>