import { EngToKorean, formatCurrency, formatDate, formatPhoneNumber, formatTimeString } from "@/utils/dataFormatUtils";

export class parkingListDTO {
  constructor({ id, parkingAreaName }) {
    this.id = id;
    this.name = parkingAreaName;
  }
}

export class parkingLotDTO {
  constructor({ id, name,seats,sido,sigungu,dong,detailAddress,lat,lng,contact,openTime,closeTime }) {
    this.id = id;
    this.name = name;
    this.seats = seats;
    this.sido = sido;
    this.sigungu = sigungu;
    this.dong = dong;
    this.detailAddress = detailAddress;
    this.lat = lat;
    this.lng = lng;
    this.contact = contact;
    this.openTime = openTime;
    this.closeTime = closeTime;
  }

  get displayData() {
    return {
      "No": this.id,
      "주차장명": this.name,
      "주차면 수": this.seats,
      "주소": `${this.sido} ${this.sigungu} ${this.dong} ${this.detailAddress}`,
      "연락처": this.contact,
      "영업 시간": `${formatTimeString(this.openTime)} ~ ${formatTimeString(this.closeTime)}`,
    };
  }

}

export class availableParkinglotDTO{
  constructor(data) {
    this.id = data.id;
    this.parkingAreaName = data.parkingAreaName;
    this.sido = data.sido;
    this.sigungu = data.sigungu;
    this.dong = data.dong;
    this.detailAddress = data.detailAddress;
    this.fullAddress = `${data.sido} ${data.sigungu} ${data.dong} ${data.detailAddress}`;
    this.minPay = data.minPay;
    this.callPay = data.callPay;
  }
}
export class requestParkingLotDTO {
  constructor(name, seats, sido, sigungu, dong, detailAddress, lat, lng, contact, openTime, closeTime) {
    this.name = name;
    this.seats = seats;
    this.sido = sido;
    this.sigungu = sigungu;
    this.dong = dong;
    this.detailAddress = detailAddress;
    this.lat = lat;
    this.lng = lng;
    this.contact = contact;
    this.openTime = openTime;
    this.closeTime = closeTime;
  }
}

export class parkingFeeDTO {
  constructor({date, dayFee}) {
    this.date = date;
    this.dayFee = formatCurrency(dayFee);
  }
}


export class reservDetailDTO {
  constructor(data) {
    this.id = data.id;
    this.parkingName = data.parkingName;
    this.reservationDate = data.reservationDate;
    this.customerPhone = data.customerPhone;
    this.vehicleType = data.vehicleType;
    this.vehicleNumber = data.vehicleNumber;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.price = data.price;
    this.flightType = data.flightType;
    this.payType = data.payType;
    this.carrierCount = data.carrierCount;
    this.golfClubCount = data.golfClubCount;
    this.peopleCount = data.peopleCount;    
  }

  get displayData() {
    return {
      "예약일시": formatDate(this.reservationDate),
      "연락처": formatPhoneNumber(this.customerPhone),
      "탑승노선": EngToKorean(this.flightType),
      "차량입고": formatDate(this.startTime),
      "차량출고": formatDate(this.endTime),
      "결제금액": `${formatCurrency(this.price)}원`,
      "결제수단": EngToKorean(this.payType),
      "차량기종": this.vehicleType,
      "차량번호": this.vehicleNumber,
      "수하물": `캐리어 ${this.carrierCount}개 / 골프백 ${this.golfClubCount}개`,
      "예약인원": `${this.peopleCount} 명`,
    };
  }
}
