<template>
  <div class="floating-container">
    <button @click="showReservationsModal = true" class="floating-button reservations">
      <img src="@/img/reservation.png" alt="Reservations">
    </button>
    <button @click="scrollToTop" class="floating-button top">
      <img src="@/img/top.png" alt="Top">
      <span>TOP</span>
    </button>
    <ReservationsModal v-model:show="showReservationsModal" />
  </div>
</template>


<script>
import ReservationsModal from '../components/ReservationModal.vue';

export default {
  components: {
    ReservationsModal
  },
  data() {
    return {
      showReservationsModal: false
    };
  },
  methods: {
    scrollToTop() {
      const scrollableElement = document.documentElement || document.body;  // 스크롤 가능한 요소 선택
      scrollableElement.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

  
<style src="../styles/floatingbutton.css"></style>
  