import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios'; // axios를 가져옵니다.

export default createStore({
  state: {
    accessToken: null,
    loginToken: null,
    refreshToken: null,

    userInfo: {
      name: null,
      role: null,
    }, // 로그인 사용자 정보

    errorMessage: '', 
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    clearAccessToken(state) {
      state.accessToken = null;
    },

    setLoginToken(state, token) {
    // Axios 헤더 업데이트
      state.loginToken = token;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; 
    },

    setRefreshToken(state, token) {
      state.refreshToken = token;
    },
    clearTokens(state) {
      // Axios 헤더 제거
      state.loginToken = null;
      state.refreshToken = null;
      delete axios.defaults.headers.common['Authorization']; 
    },

    setUserInfo(state, userInfo) {
      state.userInfo = userInfo; 
    },
    clearUserInfo(state) {
      state.userInfo = { name: null, role: null }; 
    },

    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
    clearErrorMessage(state) {
      state.errorMessage = '';
    },

  },
  actions: {
    updateAccessToken({ commit }, token) {
      commit('setAccessToken', token);
    },
    removeAccessToken({ commit }) {
      commit('clearAccessToken');
    },

    updateLoginToken({ commit }, token) {
      commit('setLoginToken', token);
    },
    updateRefreshToken({ commit }, token) {
    commit('setRefreshToken', token);
    },
    removeTokens({ commit }) {
    commit('clearTokens');
    },

    updateUserInfo({ commit }, userInfo) {
      commit('setUserInfo', userInfo);
    },
    removeUserInfo({ commit }) {
      commit('clearUserInfo');
    },

    setError({ commit }, error) {
      commit('setErrorMessage', error);
    },
    clearError({ commit }) {
      commit('clearErrorMessage');
    },
  },
  getters: {
    accessToken: (state) => state.accessToken,
    loginToken: (state) => state.loginToken,
    refreshToken: (state) => state.refreshToken,
    userInfo: (state) => state.userInfo,
    errorMessage: (state) => state.errorMessage,

  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
