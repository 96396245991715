export function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    const hour = ('0' + d.getHours()).slice(-2);
    const minute = ('0' + d.getMinutes()).slice(-2);

    return `${year}.${month}.${day} ${hour}:${minute}`;
}

export function formatTime(date) {
    const d = new Date(date);
    const optionsDate = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short'
    };
    const optionsTime = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    };

    const formattedDate = new Intl.DateTimeFormat('ko-KR', optionsDate).format(d);
    const formattedTime = new Intl.DateTimeFormat('ko-KR', optionsTime).format(d);

    // formattedDate에서 요일을 추출하여 소괄호로 감싸기
    const [year,Month,Day, weekday] = formattedDate.split(' ');
    const formattedDateWithWeekday = `${year} ${Month} ${Day} (${weekday})`;

    return `${formattedDateWithWeekday} ${formattedTime}`;
}

export function formatTimeString(timeStr) {
    if (timeStr == null) {
      return '';
    }
    
    const hours = timeStr.substring(0, 2);
    const minutes = timeStr.substring(2, 4);
    return `${hours}:${minutes}`;
  
}

export function formatCurrency(value) {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toLocaleString('ko-KR');
}

export function formatPhoneNumber(phoneNumber) {
  // 정규식을 사용하여 전화번호 형식을 변환
  const formattedNumber = phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  return formattedNumber;
}

export function EngToKorean(org) {
  const EngMapping = {
    "RESERVED": "예약완료",
    "COMPLETED": "출차완료",
    "CANCELED": "예약취소",

    "OVERSEAS": "국제",
    "DOMESTIC": "국내",

    "card": "카드",
  };
  return EngMapping[org] || org; // 매핑이 없는 경우 원래 값을 반환
}
