import axios from 'axios';
import { parkingFeeDTO, reservDetailDTO, availableParkinglotDTO } from './dto/parkingLotDTO.js';

// 인증이 필요 없는 API 클라이언트 설정
const noAuthApiClient = axios.create({
  baseURL: '/dev-api',
  // baseURL: 'https://www.u-car.co.kr/api',
  headers: {
    'Content-Type': 'application/json',
  }
});

// 에러 핸들링 함수
const handleError = (error) => {
  console.error('API 호출 중 오류 발생:', error);
  throw error;
};

const fixedParkingInfo = {
  sido: '부산광역시',
  sigungu: '강서구',
  dong: '대저2동',
  name: '',
  id: 31  // 고정된 주차장 ID
};

// 주차장 가져오기
export const getParkingList = async (startDate, endDate) => {
  try {
    const response = await noAuthApiClient.get(`/parking-areas/pair/non-customer/6/available`, { 
      params: {
        startDateTime: startDate,
        endDateTime: endDate
    }});
    return response.data.list.map(response => new availableParkinglotDTO(response));
  } catch (error) {
    handleError(error);
  }
};

// 주차장 예상요금 조회
export const getParkingFees = async (reservDate, parkinglotId) => {
  try {
    const response = await noAuthApiClient.post(`/parking-areas/${parkinglotId}/fees/calculate`, reservDate);
    return response.data.fee;
  } catch (error) {
    handleError(error);
  }
};

// 주차장 요금 조회
export const getFeeInto = async () => {
  try {
    const response = await noAuthApiClient.get(`/parking-areas/${fixedParkingInfo.id}/fees/non-customer`);
    return response.data.list.map(response => new parkingFeeDTO(response));
  } catch (error) {
    handleError(error);
  }
};

// 예약 조회
export const getReservations = async (customerPhone) => {
  try {
    const response = await noAuthApiClient.get(`/parking-areas/reservations/non-customer`, {
      params: { customerPhone }
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// 예약 상세조회
export const getReservDetail = async (reservationId, orderToken) => {
  try {
    const response = await noAuthApiClient.get(`/parking-areas/reservations/non-customer/${reservationId}`,{ 
      headers: {
        'orderToken': orderToken
      } },
    );
    return new reservDetailDTO(response.data);
  } catch (error) {
    handleError(error);
  }
};

// 예약 비밀번호 검증
export const getReservationByPassword = async (reservationId, password) => {
  try {
    const response = await noAuthApiClient.post(`/parking-areas/reservations/non-customer/${reservationId}`, { password });
    return new reservDetailDTO(response.data);
  } catch (error) {
    handleError(error);
  }
};

// 예약 등록
export const createReservation = async (parkinglotId, reservationDetails) => {
  try {
    console.log('Sending reservation details:', JSON.stringify(reservationDetails));
    const response = await noAuthApiClient.post(`/parking-areas/${parkinglotId}/reservations`, reservationDetails);
    return response.data;
  } catch (error) {
    console.error('Reservation creation error:', error.response?.data || error.message);
    handleError(error);
  }
};
