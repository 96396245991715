<template>
    <HeaderTool />
    <div id="reservation">
        <ReservationContent />
    </div>
    <div id="home">
        <HomeContent />
    </div>
    <div id="company">
        <CompanyContent />
    </div>
    <div id="solution">
        <SolutionContent />
    </div>
    <FooterTool />
    <FloatingButtons/>
</template>
  
<script>
    import HeaderTool from '@/components/Header.vue';
    import HomeContent from '@/components/HomeContent.vue';
    import CompanyContent from '@/components/CompanyContent.vue';
    import ReservationContent from '@/components/ReservationContent.vue'
    import FooterTool from '@/components/Footer.vue';
    import FloatingButtons from '@/components/FloatingButton.vue';
    import SolutionContent from '@/components/SolutionContent.vue';
    
    export default {
      components: {
        HeaderTool, HomeContent,  CompanyContent, SolutionContent, FloatingButtons, FooterTool
        , ReservationContent
      }
    }
</script>
  