<template>
    <OtherPolicy />
  </template>
  
<script>
  import OtherPolicy from '@/components/OtherPolicy.vue'
  
  export default {
    components: {
      OtherPolicy
    }
  }
</script>
  