<template>
    <div class="terms">
        <p>[ 주식회사 잇다모빌리티 ]는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</p>
        <p>○ 이 개인정보처리방침은 2022년 6월 9일부터 적용됩니다</p>
        <h3>제1조(개인정보의 처리 목적)</h3>
        <p>[ 주식회사 잇다모빌리티 ]는 다음의 목적을 위하여 개인정보를 처리합니다. 
처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </p>
        <ol>
            <li>재화 또는 서비스 제공 <br/>
                서비스 제공, 본인인증을 목적으로 개인정보를 처리합니다.
                </li>
        </ol>
        <h3>제2조(개인정보의 처리 및 보유 기간)</h3>
        <ol class="nested">
            <li>① [ 주식회사 잇다모빌리티 ]는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</li>
            <li>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</li>
            <li>1. 재화 또는 서비스 제공<br/>「재화 또는 서비스 제공」와 관련한 개인정보는 수집.이용에 관한 동의일로부터[5년]까지 위 이용목적을 위하여 보유.이용됩니다.</li>
            <li>보유근거 : 서비스 이용 및 개선</li>
            <li>관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 5년</li>
        </ol>
        <h3>제3조(처리하는 개인정보의 항목)</h3>
        <ol class="nested">
            <li>① [ 주식회사 잇다모빌리티 ]는 다음의 개인정보 항목을 처리하고 있습니다. <br/>
            1. 재화 또는 서비스 제공
            </li>
            <li>필수항목 : 휴대전화번호, 차량 기종, 차량 번호, 위치 정보</li>
        </ol>
        <h3>제4조(개인정보의 파기절차 및 파기방법)</h3>
        <ol class="nested">
            <li>① [ 주식회사 잇다모빌리티 ] 는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>
            <li>② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</li>
            <li>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</li>
            <li>1. 파기절차 <br/>
            [ 주식회사 잇다모빌리티 ] 는 파기 사유가 발생한 개인정보를 선정하고,
            [ 주식회사 잇다모빌리티 ] 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
            </li>
            <li>2. 파기방법 <br/>
            전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다
            </li>
        </ol>
        <h3>제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</h3>
        <ol class="nested">
            <li>① 정보주체는 주식회사 잇다모빌리티에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</li>
            <li>② 제1항에 따른 권리 행사는 주식회사 잇다모빌리티에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 주식회사 잇다모빌리티은(는) 이에 대해 지체 없이 조치하겠습니다.</li>
            <li>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
            <li>④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</li>
            <li>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
            <li>⑥ 주식회사 잇다모빌리티 는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
        </ol>
        <h3>제6조(개인정보의 안전성 확보조치에 관한 사항)</h3>
        <p>[ 주식회사 잇다모빌리티 ]는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
        <ol>
        <li>개인정보에 대한 접근 제한 <br/>
        개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
        </li>
        <li>개인정보에 대한 암호화 <br/>
        개인정보를 관리하는 데이터베이스시스템에 다음의 정보에 대해 암호화하여 저장 및 관리하고 있습니다.<br/>
        - 휴대전화번호, 차량 기종, 차량 번호
        </li>
        </ol>
        <h3>제7조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</h3>
        <ol class="nested">
            <li>① 주식회사 잇다모빌리티 는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</li>
            <li>② 쿠키는 웹사이트를 운영하는데 이용되는 서버(https)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</li>
            <li>가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>
            <li>나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</li>
            <li>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
        </ol>
        <h3>제8조 (개인정보 보호책임자에 관한 사항)</h3>
        <ol class="nested">
            <li>① 주식회사 잇다모빌리티 는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</li>
            <li>▶ 개인정보 보호책임자</li>
            <li>성명 : 최종규</li>
            <li>연락처 : 010-2559--8000</li>
            <li>※ 개인정보 보호 담당부서로 연결됩니다.</li>
        </ol>
        <h3>제9조(개인정보의 열람청구를 접수·처리하는 부서)</h3>
        <p>정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
        <br/> [ 주식회사 잇다모빌리티 ] 는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
        </p>
        <h3>제10조(정보주체의 권익침해에 대한 구제방법)</h3>
        <p>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</p>
        <ol>
            <li>개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</li>
            <li>개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
            <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
            <li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
        </ol>
        <p>「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
        <br/>※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
        </p>
        <h3>제11조(고지의 의무)</h3>
        <ol>
            <li>본 방침은 정부나 회사의 정책이 변경됨에 따라 내용의 추가 및 삭제, 수정이 있을 수 있으며, 이 경우 시행 7일 전에 홈페이지를 통해 사전 공지하고 사전 공지가 곤란한 경우 지체 없이 공지합니다.</li>
            <li>회원은 개인정보의 수집, 이용 등과 관련한 위의 사항에 대하여 원하지 않는 경우 동의를 거부할 수 있습니다. 다만, 회원이 동의를 거부하는 경우 서비스의 전부 또는 일부를 이용할 수 없음을 알려드립니다.</li>
            <li>회사가 변경 내용의 고지를 통해 안내한 변경 적용일까지 회원이 명시적으로 거부 의사를 표시하지 않는 경우, 변경 내용에 동의한 것으로 간주합니다.</li>
            <li>또한 당사는 개인정보의 수집 및 활용, 제3자 제공 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령에 따라 회원의 별도 동의가 필요한 사항과 관련된 내용이 추가, 변경되는 경우에는 관련 법령에 따른 회원의 별도 동의를 받습니다.</li>
        </ol>
        <h3>제13조 (개인정보 처리 방침 및 이용약관 등에 대한 명시적 거부 의사 방법)</h3>
        <ol><li>개인정보 처리 방침 및 이용약관 등에 대한 명시적 거부 의사는 어플 내 이용안내 및 탈퇴 - 앱 이용 문의를 통해 할 수 있습니다.</li></ol>
        <h2>부칙</h2>
        <ol>
        <li>이 개인정보처리방침은 2024년 8월 1일부터 적용됩니다.</li>
        </ol>
    </div>
</template>

<script>
export default { name: 'LocationPolicy'}
</script>

<style scoped>
.nested {list-style-type: none; padding-left: 0;}
.terms {padding: 20px 40px; background-color: white; font-size: 14px;}
ol {padding-left: 10px;}
</style>