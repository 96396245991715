<template>
    <div class="main-page">
        <div class="background-wrapper" :style="{ backgroundImage: `url(${backgroundSrc})` }">
            <div class="main-info">
                <p>김해국제공항의 주차질서와 품격을 높이는</p>                               
                <img src="../img/main-logo.svg" />
                <p>간단하고 안전한 주차 솔루션 앱을 경험하세요.</p>
            </div>
            <div class="overlay">
                <div class="qr-and-buttons">
                    <div class="qr-code">
                        <img v-if="qrCodeSrc" :src="qrCodeSrc" alt="QR Code" >
                    </div>
                    <div class="buttons">
                        <button @click="showAppStoreQR" class="store-button">
                            <img src="../img/applebutton.svg" alt="App Store" />
                        </button>
                        <button @click="showGooglePlayQR" class="store-button">
                            <img src="../img/androidbutton.svg" alt="Google Play" />
                        </button>
                    </div>
                </div>
                <div class="instruction">
                    <p >손쉬운 예약과 확인을 원하시면 QR코드를 이용하여 앱을 다운로드 해보세요.</p>
                </div>
            </div>
            <div class="page-indicator">
                <span v-for="(_, index) in totalPages" :key="index" 
                      :class="{ 'active': currentPage === index + 1 }"></span>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'HomeContent',
    data() {
        return {
            isSmallScreen: window.innerWidth <= 900,
            backgroundSrc: window.innerWidth <= 900 ? require('../img/m-main.png') : require('../img/main.png'),
            qrCodeSrc: require('../img/iosQR.svg'), // QR 코드 이미지 경로
            currentPage: 1,
            totalPages: 2
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);
    },
    methods: {
        updateScreenSize() {
            this.isSmallScreen = window.innerWidth <= 900;
            this.backgroundSrc = this.isSmallScreen ? require('../img/m-main.png') : require('../img/main.png');
        },
        showAppStoreQR() {
            this.backgroundSrc = require('../img/main.png'); // App Store 배경 이미지
            this.qrCodeSrc = require('../img/iosQR.svg'); // App Store QR 코드 경로
            this.currentPage = 1;
        },
        showGooglePlayQR() {
            this.backgroundSrc = require('../img/bg2.svg'); // Google Play 배경 이미지
            this.qrCodeSrc = require('../img/androidQR.svg'); // Google Play QR 코드 경로
            this.currentPage = 2;
        }
    },
}
</script>


<style src="../styles/homecontent.css"></style>
