<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-message">예약 취소 중...</div>
      </div>
      <span @click="closeModal" class="modalclose-button">×</span>
      <h2>예약 내역 조회</h2>
      <p style="margin: .5rem 0;">연락처</p>
      <div class="search-section">
        <input id="contact" v-model="contact" type="text" placeholder="ex. 01012345678" />
        <button @click="searchReservations">조회</button>
      </div>
      <table v-if="reservations.length > 0" class="reserv-table">
        <thead>
          <tr>
            <th>연락처</th>
            <th>예약시설</th>
            <th>예약날짜</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="reservation in paginatedReservations" :key="reservation.id">
            <tr>
              <td>{{ maskContact(reservation.customerPhone) }}</td>
              <td>{{ maskContact(reservation.parkingAreaName) }}</td>
              <td>{{ formatDate(reservation.reservationDate) }}</td>
              <td>
                <button v-if="expandedReservationId != reservation.id" @click="openPasswordModal(reservation, reservation.id, 'showDetails')">자세히보기 ▽</button>
                <button v-else-if="expandedReservationId === reservation.id" @click="closeDetail">접기 △</button>
              </td>
            </tr>
            <tr v-if="expandedReservationId === reservation.id" :key="expandedReservationId + '-details'">
              <td colspan="4" style="padding: 0;">
                <table class="details-table">
                  <tbody>
                    <tr>
                      <td class="label">예약시설</td>
                      <td class="data">{{ selectedAddress.parkingAreaName}}</td>
                    </tr>
                    <tr>
                      <td class="label">상세주소</td>
                      <td class="data">{{ selectedAddress.fullAddr }}</td>
                    </tr>
                    <tr v-for="(value, key) in reservDetail" :key="key">
                      <td class="label">{{ key }}</td>
                      <td class="data">{{ value }}</td>
                    </tr>
                  </tbody>
                </table>
                <button v-if="cancelEnable" @click="cancelReservation(expandedReservationId)" class="cancel-button">예약 취소하기</button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="error" class="no-reserv">
         {{error}}
      </div>
      <div class="pagination" v-if="reservations.length > 0">
        <button @click="prevPage" :disabled="currentPage === 1">&laquo;</button>
        <span v-for="page in totalPages" :key="page" @click="setPage(page)" :class="{ active: currentPage === page }">{{ page }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">&raquo;</button>
      </div>
    </div>

    <div v-if="webReserv" class="modal-overlay">
      <div class="password-modal-content">
        <span @click="closePasswordModal" class="modalclose-button">×</span>
        <h3>예약 시 입력하신 비밀번호를 입력해주세요.</h3>
        <p>비밀번호를 잊어버리신 경우 문의하여 주시기 바랍니다.</p>
        <div v-if="passwordError" class="error-message">{{ passwordError }}</div>
        <input v-model="password" :class="{ 'error': passwordError }" type="password" placeholder="비밀번호를 입력하세요" />
        <button @click="verifyPassword">확인</button>
      </div>
    </div>
    <div v-if="appReserv" class="modal-overlay">
      <div class="password-modal-content">
        <span @click="closePasswordModal" class="modalclose-button">×</span>
        <h3>해당 예약에 대한 상세 내역은 앱에서 확인해주세요.</h3>
        <button @click="closePasswordModal">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getReservations, getReservationByPassword } from '@/api/userapi';
import { cancelPayment } from '@/api/payple';
import { formatDate } from '@/utils/dataFormatUtils';

export default {
  props: ['show'],
  data() {
    return {
      contact: '',
      reservations: [],
      expandedReservationId: null,
      currentPage: 1,
      itemsPerPage: 20,
      webReserv: false,
      appReserv: false,
      selectedReservationId: null,
      selectedAddress: '',
      password: '',
      passwordError: '',
      isLoading: false,
      error: null,
      actionAfterPassword: null,
      reservDetail:'',
      cancelEnable: true,
    };
  },
  computed: {
    selectedReservation() {
      return this.reservations.find(reservation => reservation.id === this.expandedReservationId);
    },
    paginatedReservations() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.reservations.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.reservations.length / this.itemsPerPage);
    }
  },
  methods: {
    async searchReservations() {
      this.error = null;
      try {
        const response = await getReservations(this.contact);
        this.reservations = response.list || [];
        console.log(response.list)
        if (response.list.length === 0) {
          console.log(`비어있음`)
          this.error = '입력한 정보로 예약된 내용이 없습니다.\n연락처를 확인해주세요.';
        }
        this.expandedReservationId = null;
        this.currentPage = 1;
      } catch (error) {
        console.error('Error fetching reservations:', error);
        this.error = '예약 정보를 불러오는데 실패했습니다.';
      } 
    },
    closeModal() {
      document.body.classList.remove('modal-open');
      this.reservations = [];
      this.contact = '';
      this.$emit('update:show', false);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setPage(page) {
      this.currentPage = page;
    },
    openPasswordModal(reservdata, reservationId, action) {
      this.selectedReservationId = reservationId;
      this.selectedAddress = reservdata;
      this.selectedAddress.fullAddr = `${reservdata.parkingAreaSido} ${reservdata.parkingAreaSigungu} ${reservdata.parkingAreaDong} ${reservdata.parkingAreaDetailAddress} `
      this.actionAfterPassword = action;
      if (reservdata.customerType === '비회원') {
        this.webReserv = true
      } else {
        this.appReserv = true
      }
      this.password = ''; // Clear password every time to force re-entry
    },
    closeDetail(){
      this.expandedReservationId = null;
    },
    closePasswordModal() {
      this.webReserv = false;
      this.appReserv = false;
      this.passwordError = '';
    },
    async verifyPassword() {
      try {
        const result = await getReservationByPassword(this.selectedReservationId, this.password);
        const today = new Date();
        if (result) {
          this.closePasswordModal();
          this.reservDetail = result.displayData
          if (result.startTime === today){
            this.cancelEnable = false;
          }
          this.showDetails(this.selectedReservationId);
        }
      } catch (error) {
        console.error('비밀번호 검증 실패:', error);
        this.password = ''
        if (error.response.data.code === 'L-001'){
          this.passwordError = '비밀번호가 틀렸습니다.';
        } else {
          this.passwordError = '비밀번호 확인 중 에러가 발생했습니다.\n다시 시도해주세요';
        }
      }
    },
    showDetails(reservationId) {
      this.expandedReservationId = reservationId;
    },
    async cancelReservation(reservationId) {
      // 확인 팝업 띄우기
      const confirmed = confirm(`예약을 취소하시겠습니까?\n확인버튼을 누르면 예약이 취소됩니다.`);

      if (confirmed) {
        try {
          this.isLoading = true;
          const cancelResponse = await cancelPayment(reservationId, this.password);
          if (cancelResponse.status === 200) {
            this.reservations = this.reservations.filter(res => res.id !== reservationId);
            this.expandedReservationId = null;
            alert('예약이 성공적으로 취소되었습니다.');
            window.location.href = '/'; 

          } else {
            console.log('예약 취소 실패: ' + cancelResponse.message);
          }
        } catch (error) {
          console.error('예약 취소 오류:', error);
          alert('예약 취소에 실패했습니다.');
        } finally {
          this.isLoading = false;
        }
      }
    },
    maskContact(contact) {
      return contact.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3');
    },
    formatDate
  }
}
</script>

<style src="../styles/reservemodal.css"></style>
