<template>
  <div class="modal-backdrop" v-if="show">
    <div class="success-content">
      <h2>예약하시겠습니까?</h2>
      <p>예약 수정/조회 시에는 등록암호를 이용해 가능합니다.</p>
      <button @click="confirmReservation" class="confirm">확인</button>
      <button @click="cancel" class="cancel">취소</button>
    </div>
  </div>
</template>

<script>
import { createReservation } from '@/api/userapi.js';
import {jwtDecode}  from 'jwt-decode';

/* global PaypleCpayAuthCheck */

export default {
  props: ['show', 'price', 'reservationDetails', 'parkinglot'],
  methods: {
    async confirmReservation() {
      try {
        const reservationResponse = await createReservation(this.parkinglot.id, this.reservationDetails);
        const decodedToken = jwtDecode(reservationResponse.orderToken);

        sessionStorage.setItem('reservParkinglot', this.parkinglot);
        sessionStorage.setItem('reservDetail', this.reservationDetails);
        sessionStorage.setItem('reservId', JSON.stringify(decodedToken));

        if (!reservationResponse.orderToken) {
          throw new Error('예약 생성 실패: 주문 토큰이 없습니다.');
        }

        await this.loadPaymentScript();
        this.initiatePayment(reservationResponse.orderToken, this.reservationDetails.price);

      } catch (error) {
        if (error.response.data.code === 'G-003'){
          alert('앱을 사용하시는 고객님께서는 앱에서 예약을 진행해주세요');
        } else if (error.response.data.code === 'C-003') {
          alert('해당 주차장의 만차로 예약이 불가합니다. 다른 주차장을 선택해주세요.');
        } else {
          alert('예약 중 에러가 발생했습니다. 다시 시도해주세요.');
        }
        this.cancel();
      }
    },

    loadPaymentScript() {
      return new Promise((resolve, reject) => {
        if (typeof PaypleCpayAuthCheck !== 'undefined') {
          resolve();
          return;
        }
        let script = document.createElement('script');
        script.src = "https://cpay.payple.kr/js/v1/payment.js";
        script.onload = () => {
          console.log('Payple script loading');
          resolve();
        };
        script.onerror = () => {
          console.error("failed loading");
          reject(new Error("결제 스크립트 로딩에 실패했습니다."));
        };
        document.head.appendChild(script);
      });
    },

    initiatePayment(orderToken) {
      PaypleCpayAuthCheck({
        PCD_PAY_TYPE: "card",
        PCD_PAY_WORK: "CERT",
        PCD_CARD_VER: "02",
        PCD_PAY_GOODS: "유카주차장",
        PCD_PAY_TOTAL: this.reservationDetails.price,
        PCD_RST_URL: `/api/payments/reservations?orderToken=${orderToken}`,
        clientKey: `AE3C391AD64569CC713443C4EDBDBD66`
      });
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style src="../styles/confirmmodal.css"></style>
