<template>
    <footer class="footer">
      <div class="footer-top">
        <div class="footer-menu">
          <!-- <a href="#" @click="scrollToSection('company', $event)">서비스 이용약관</a>|
          <a href="#" @click="scrollToSection('company', $event)">개인정보 처리방침</a>| -->
          <a href="#" @click="scrollToSection('company', $event)">회사소개</a>|
          <a href="#" @click="scrollToSection('reservation', $event)">온라인예약</a>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="footer-section">
          <img src="@/img/ucar-logo.svg" alt="Scoop Logo" class="footer-logo">
        </div>
        <div class="footer-section footer-info">
          <p>주차장 운영사</p>
          <p>(주)잇다모빌리티 |  사업자등록번호 : 697-87-01463 | 대표전화: 010-2559-8000</p>
          <p>법인 사업자주소 : 부산광역시 강서구 대저2동 2157번지</p>
          <p>주차장 주소 : 부산광역시 강서구 대저2동 2120-4번지</p>
          <p>COPYRIGHT © 2024 유카파킹. All Rights Reserved</p>
        </div>
        <div class="footer-info">
          <p>플랫폼 운영사</p>
          <p>(주)쉽차장 |  사업자등록번호 : 414-87-03129 | 대표전화: 010-7515-5087</p>
          <p>법인 사업자주소 : 광주광역시 동구 동계천로 150, I-PLEX 103호</p>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
    name: 'HeaderTool',
    methods: {
        scrollToSection(sectionId, event) {
            event.preventDefault();
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>

<style src="../styles/footer.css"></style>
  