<template>
    <div class="company-page">
        <div class="company-info">
            <p>저희 유카파킹은 김해국제공항과 함께 주차가 필요한 고객 여러분들게 편리하고 안전한 주차공간을 제공하기 위하여 최선의 노력을 다하고 있습니다.</p>
            <p>다양한 상품의 주차공간과 편리한 접근성은 여러분의 여행을 더욱 편리하게 만들어 드립니다. 
                고객 여러분의 소중한 차량을 보관하고 실시간 신속한 PICK-UP 차량서비스를 통하여 </p>
            <p>최상의 고객만족을 다하는 조직문화를 만들고자 하며 고객 여러분들의 많은 지원과 이용에 항상 깊은 감사를 드립니다. </p>  
            <p class="company-end">--유카파킹 임직원 일동</p>
        </div>
    </div>
</template>

<style src="../styles/company.css"></style>