<template>
  <div class="modal-backdrop" v-if="show">
    <div class="success-content">
      <h2>최소결제금액 미달</h2>
      <p>고객님의 결제 금액은 {{ estimatedFee }}원으로, 최소 이용 금액인 {{ minPay }}원 미달로 예약이 불가능합니다.</p>
      <p>차량 입고.출고일을 다시 설정해주세요</p>
      <button @click="confirm" class="confirm">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['show', 'estimatedFee', 'minPay'],
  methods: {
    async confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style src="../styles/confirmmodal.css"></style>
