<template>
    <header class="header-web">
        <div class="logo">
            <img src="../img/logo.svg" alt="Logo" />
        </div>
        <div class="menu-toggle" @click="toggleMenu">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <nav :class="{ 'menu': true, 'menu-active': isMenuActive }">
            <button class="close-menu" @click="toggleMenu">&times;</button>
            <ul>
                <li><a href="#" :class="{ 'active-link': currentSection === 'reservation' }" @click="scrollToSection('reservation', $event)">예약하기</a></li>
                <li><a href="#" :class="{ 'active-link': currentSection === 'home' }" @click="scrollToSection('home', $event)">앱설치</a></li>
                <li><a href="#" :class="{ 'active-link': currentSection === 'company' }" @click="scrollToSection('company', $event)">회사소개</a></li>
                <li><a href="#" :class="{ 'active-link': currentSection === 'solution' }" @click="scrollToSection('solution', $event)">이용안내</a></li>
            </ul>
        </nav>
    </header>
</template>

<script>
export default {
  name: 'HeaderTool',
  data() {
    return {
      currentSection: '',
      isMenuActive: false
    };
  },
  methods: {
    scrollToSection(sectionId, event) {
      event.preventDefault();
      const element = document.getElementById(sectionId);
      if (element) {
        const headerOffset = 0; 
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollBy({
          top: offsetPosition,
          behavior: 'smooth'
        });

        this.currentSection = sectionId;
        this.isMenuActive = false; // Close menu after click
      }
    },
    checkSection() {
      const sections = ['home', 'solution', 'company', 'app-section', 'reservation'];
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (const section of sections) {
        const element = document.getElementById(section);
        if (element && scrollPosition >= element.offsetTop && scrollPosition <= element.offsetTop + element.offsetHeight) {
          this.currentSection = section;
          break;
        }
      }
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.checkSection);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.checkSection);
  }
}
</script>

<style src="../styles/header.css"></style>