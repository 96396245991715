<template>
  <div id="cancel-app">
    <div class="reservation-detail">
      <p class="reserv">예약완료</p>
    </div>
    <div class="reservation-detail">
      <div class="detail-item" v-for="(value, key) in reservDetail" :key="key">
        <div class="key">{{ key }}</div > 
        <div class="value">: {{ value }}</div >
      </div>
      <hr class="dashed-line">
      <div class="button-container">
        <button @click="cancelPayment" :disabled="isCancelling">예약취소</button>
      </div>
    </div>
    <div v-if="isCancelling" class="modal">
      <div class="modal-content">
        <p style="margin: auto;">예약취소중...</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { reservDetailDTO } from '@/api/dto/parkingLotDTO';

export default {
  data() {
    return {
      isCancelling: false,
      message: '',
      reservationId: '',
      orderToken: '',
      reservDetail: '',
    };
  },
  methods: {
    async cancelPayment() {
      this.isCancelling = true;
      const url = `https://www.u-car.co.kr/dev-api/payments/reservations/cancel/web-view?reservationId=${this.reservationId}&orderToken=${this.orderToken}`;

      try {
        const response = await axios.post(url, null, { maxRedirects: 0 });

        console.log(response);
        const redirectUrl = response.request.responseURL;

        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          console.log('리다이렉션 URL이 응답에 포함되지 않았습니다.');
        }
      } catch (error) {
        console.error('결제 취소 오류:', error);
        this.message = '네트워크 오류로 결제 취소에 실패했습니다.';
      } finally {
        this.isCancelling = false;
      }
    },
    async fetchReservDetail() {
      try {
        const response = await axios.get(`https://www.u-car.co.kr/dev-api/parking-areas/reservations/non-customer/${this.reservationId}`,{ 
          headers: {
            'orderToken': this.orderToken
          } },
        );
        console.log("response",response.data)
        this.reservDetail = new reservDetailDTO(response.data).displayData
        console.log('상세정보', this.reservDetail)
      } catch (error) {
        console.log(error.data)
      }
    },
    
    sendError() {
      const message = {
        status: 'TokenExpired',
        msg: 'this token is expired',
        code: null,
      };
          
      const jsonString = JSON.stringify(message);

      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.onPaymentResult) {
        window.webkit.messageHandlers.onPaymentResult.postMessage(jsonString);
      } else if (window.Android) {
        window.Android.onPaymentResult(JSON.stringify(jsonString));
      } else {
        alert(`데이터를 불러오던 중 에러가 발생했습니다. 다시 시도해주세요`);
        window.location.href = '/'; 
        
      }
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.reservationId = urlParams.get('reservationId');
    this.orderToken = urlParams.get('orderToken');
    this.fetchReservDetail()
  }
};
</script>

<style scoped>
#cancel-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 1rem;
}

.reservation-detail {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
}


.reserv {
  background-color: #556290;
  padding: .5rem .8rem;
  font-size: .9rem;
  font-weight: bold;
  color: white;
  text-align: start;
  margin: 0;
}

.detail-item {
  display: flex;
  flex-direction: row;
  padding: 0 .8rem;
  margin: .5rem 0rem;
  align-items: center;
}

.key, .value, .separator {
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
  font-size: .9rem;
  height: 100%;
}

.key {
  color: gray;
  text-align: center;
  width: 55px;
}

.separator {
  margin: 0 .3rem;
}

.value {
  text-align: start;
  width: 200px;
}

.error {
  color: #9c2828;
  margin-top: 20px;
}

.dashed-line {
  border: none;
  border-top: 1px dashed gray;
  background-color: transparent;
  margin: 15px 0;
}

.button-container {
  display: flex;
}

button {
  width: 100%;
  padding: .8rem .2rem;
  color: #BB2020;
  font-weight: bold;
  font-size: 1.1rem;
  border: 2px solid #BB2020;
  border-radius: 5px;
  cursor: pointer;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
</style>